html {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  color: #fff;
  background-color: #000;
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
  font-weight: 300;
  overflow: hidden;
}

#video-container {
  width: 100%;
  height: 100%;
}

#video {
  width: 100%;
  height: 100%;
  margin: auto;
}

.shaka-text-container {
  font-size: 4.4vh;
}

#idle {
  z-index: 99;
  background-color: #000;
  background-repeat: no-repeat;
  padding-top: 60px;
  padding-left: 0;
  animation: 90s linear infinite bg-change;
  position: absolute;
  inset: 0;
}

#idle h1 {
  margin-left: 100px;
}

#idle h2 {
  width: 550px;
  margin-left: 100px;
  font-weight: normal;
}

body:after {
  width: 0;
  height: 0;
  z-index: -1;
  content: url("idle1.5c0c445e.jpg") url("idle2.49ffb3d3.jpg") url("idle3.c04e58dd.jpg");
  position: absolute;
  overflow: hidden;
}

@keyframes bg-change {
  0% {
    background-image: url("idle1.5c0c445e.jpg");
    padding-left: 0;
  }

  32% {
    background-image: url("idle1.5c0c445e.jpg");
    padding-left: 0;
  }

  34% {
    background-image: url("idle2.49ffb3d3.jpg");
    padding-left: 0;
  }

  49% {
    background-image: url("idle2.49ffb3d3.jpg");
    padding-left: 0;
  }

  50% {
    background-image: url("idle2.49ffb3d3.jpg");
    padding-left: 400px;
  }

  65% {
    background-image: url("idle2.49ffb3d3.jpg");
    padding-left: 400px;
  }

  67% {
    background-image: url("idle3.c04e58dd.jpg");
    padding-left: 400px;
  }

  87% {
    background-image: url("idle3.c04e58dd.jpg");
    padding-left: 400px;
  }

  88% {
    background-image: url("idle3.c04e58dd.jpg");
    padding-left: 0;
  }

  98% {
    background-image: url("idle3.c04e58dd.jpg");
    padding-left: 0;
  }

  100% {
    background-image: url("idle1.5c0c445e.jpg");
    padding-left: 0;
  }
}

/*# sourceMappingURL=index.4e521c25.css.map */
