/** @license
 * Shaka Player
 * Copyright 2016 Google LLC
 * SPDX-License-Identifier: Apache-2.0
 */

/* Experimentation has revealed that unless html, body, and video-container are
 * width and height 100%, video can force all its parents to grow larger than
 * window.innerHeight, causing things to be cut off for some content.
 */
html {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: Roboto, sans-serif;
  font-weight: 300;
  background-color: black;
  color: white;
}

#video-container {
  width: 100%;
  height: 100%;
}

#video {
  width: 100%;
  height: 100%;
  margin: auto;
}

/* The UI default style for fullscreen is to make the font size relative to the
 * window height.  That is also appropriate for the cast receiver experience.
 * Since playback here is not technically in fullscreen mode, we just duplicate
 * the style. */
.shaka-text-container {
  font-size: 4.4vh;
}

#idle {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  /* Make sure the idle cards sit above everything in the Player UI.  That way,
   * they will be visible on top until we decide to hide them and show the
   * player. */
  z-index: 99;
  padding-top: 60px;
  padding-left: 0;
  background-color: black;

  /* To make it easier to view in a browser in some non-Chromecast size: */
  background-repeat: no-repeat;

  /* Chromecast receiver guidelines say to change the screen every 30-60s */
  animation: bg-change 90s linear infinite;
}

#idle h1 {
  margin-left: 100px;
}

#idle h2 {
  font-weight: normal;
  margin-left: 100px;
  width: 550px;
}

/* Preload the background images for idle mode */
body:after {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  z-index: -1;
  content: url("idle1.jpg") url("idle2.jpg") url("idle3.jpg");
}

@keyframes bg-change {
  0% { background-image: url("idle1.jpg"); padding-left: 0; }

  32% { background-image: url("idle1.jpg"); padding-left: 0; }

  34% { background-image: url("idle2.jpg"); padding-left: 0; }

  49% { background-image: url("idle2.jpg"); padding-left: 0; }

  50% { background-image: url("idle2.jpg"); padding-left: 400px; }

  65% { background-image: url("idle2.jpg"); padding-left: 400px; }

  67% { background-image: url("idle3.jpg"); padding-left: 400px; }

  87% { background-image: url("idle3.jpg"); padding-left: 400px; }

  88% { background-image: url("idle3.jpg"); padding-left: 0; }

  98% { background-image: url("idle3.jpg"); padding-left: 0; }

  100% { background-image: url("idle1.jpg"); padding-left: 0; }
}
